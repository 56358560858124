const writings = [
  {
    id: 1,
    title: "The Search Problem",
    slug: "the-search-problem",
    excerpt: "Search is broken",
    content: 
    "# The Problem\n\n" +

    "Searching for information is a tedious and inefficient process. Traditionally, when we need to find information, we turn to Google, sift through numerous search results, read snippets, and perform iterative searches until we find what we’re looking for.\n\n" +
    "This repetitive low-level process doesn’t benefit us in any way. We don’t learn anything new, and it takes time away from higher-level thinking we could be doing with the right information readily available.\n\n" +

    "Our reliance on LLMs for instant, perfectly tailored responses shows how clear this problem is—we can’t imagine our lives without them now. However, there’s a tradeoff with misinformation due to the probabilistic nature of these models and lack of access to information beyond their training data. This leads to a misalignment with the intrinsic motivation of search, which is to find accurate and credible information.\n\n" +

    "Perplexity attempts to address this by essentially combining an LLM + up-to-date information from the web. It has definitely improved, especially with Pro, but it’s still not fully reliable for two reasons:\n" +
    "- Hallucination: there's no guarantee that LLM-generated responses are free of this\n" +
    "- Low quality sources: sometimes, the references provided are not sources I would choose myself, which leads me to look elsewhere for better information\n\n" +

    "I explored a few ideas to address this problem:\n\n\n\n" +

    "### Semantic Search\n\n" +
    "My first attempt was to build a semantic search system using vector embeddings for research papers—a typical first approach to addressing search.\n\n" +

    "However, the biggest issue was curating a high-quality, scalable index—which was way beyond what I could manage alone. If I were to use this product, I felt that I would potentially miss out on good sources, which ultimately led me to turn back to Google.\n\n" +

    "### Query Expansion\n\n" +
    "Instead of working with my own index, I thought about building on top of Google. I remembered learning about advanced search operators back in middle school, but they were too tedious to manually use despite their benefits.\n\n" +

    "The idea was to fine-tune an LLM for generating these advanced Google search queries with search operators and extrapolated keywords. This sounded good in theory since people were already doing this manually to find high-quality information.\n\n" +

    "In practice, this worked to a certain extent: it helped capture semantic relationships within queries and was effective for finding research papers and articles where keywords were straightforward to generalize. However, it didn’t lead to a massive improvement and the results were too limiting sometimes.\n\n" +

    "### Web Browsing Agent\n\n" +
    "Search engines are meant to be used by humans, in an iterative process of querying and manual filtering. So I thought of <a href='/#/projects/query' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>an agent that would browse as a typical human user would</a>.\n\n" +

    "This agent generates search queries for Google, evaluates the results for accuracy and credibility, skims through content, and performs more detailed queries to get closer to what the user wants. It mimics the iterative nature of search, which is crucial to consider given how the web is currently structured.\n\n" +

    "This was the most successful approach out of all, but it still has limitations that prevent it from fully replacing traditional search.\n\n\n\n" +

    "# Reliability\n\n" +
    "The most critical issue I see with current AI search tools—and AI as a whole—is reliability. Getting quick, tailored responses is great, but their value diminishes when considering questions like: 'is this information accurate?' or 'are there better sources that were missed?'\n\n" +

    "These concerns often lead people back to traditional search methods where accuracy and credibility are prioritized over latency.\n\n" +

    "I think a good next step to explore is to not reinvent search entirely, but to think about how we can enhance it while keeping a human in the loop. If a human guides the process, reliability becomes less of an issue, and AI can serve to accelerate rather than replace manual search efforts.\n\n" +

    "Another potential direction for the future beyond this would be creating web interfaces specifically designed for AI. The current web is built for human users, but how can we make it more interpretable and interactive for AI systems?\n\n" +

    "Ultimately, the goal is to create a search experience that leverages both human intuition and AI efficiency.\n"

  ,
    date: "2024-08-09"
  },
  {
    id: 2,
    title: "Graphs, Maps, and Apples",
    slug: "why-do-we-use-graphs",
    excerpt: "Thoughts on knowledge representation",
    content: 
    "We still lack a clear understanding of how we represent knowledge internally. \n\n" +

    "We know that we connect ideas through relationships (similar to a graph) while also relying on continuous dimensions (e.g., how \"big\" or \"small\" something is). Our thinking is also hierarchical: we first grasp a concept broadly, then dive deeper into its specifics. \n\n" +
    "Visual concepts are also an interesting area to think about. When we visualize, we never see a complete picture immediately—how do we represent these ideas internally?\n\n"+

    "# Graphs & Maps\n\n" +
    "When we think about different concepts, we often relate them to other ideas—like nodes in a graph with edges connecting related concepts. For example, we know that a \"dog\" is connected to \"mammal\", which further connects to broader categories like \"animal.\" We understand concepts through their relationships with one another—forming what is essentially a knowledge graph.\n\n"+
    "At the same time, we also think in terms of continuous measures. We might think of animals along dimensions such as leg length or neck length, arranging them along a spectrum. This spatial, map-like representation helps us compare items based on quantitative attributes.\n\n"+
    "The idea of cognitive maps has been around for decades, first proposed by <a href='https://pubmed.ncbi.nlm.nih.gov/18870876/' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>Tolman</a> who suggested that animals form representations of environments in a way that is more complex than a series of stimulus-response associations. \n\n" +
    "Since then, two major approaches emerged on the nature of these cognitive maps: euclidean maps and cognitive graphs:\n\n" +
    "- Euclidean Maps: organize concepts along continuous dimensions in a semantic space. We can compare items along specific metrics, making them useful for understanding similarities based on concrete features.\n" +
    "- Cognitive Graphs: focus on connections and relationships between concepts rather than their position in a fixed space. Graphs are good for capturing discrete, hierarchical relationships and enable inferences like transitive reasoning.\n" +
   
    "![graph 1](/images/graphs1.png)\n\n" +

    "Behavioral studies have shown faster response times for concepts closely related in a knowledge graph, suggesting that our mental representations are influenced by the \"distance\" between concepts. Neuroscience research using multivoxel pattern analysis (MVPA) of fMRI data found that, in several brain regions, neural patterns for related items were more similar than for unrelated items. This similarity indicates that the brain groups related concepts in a structured, graph-like way, reflecting higher-order relationships.\n\n" +

    "Our knowledge is likely represented by a combination of both maps and graphs, depending on the context. However, current knowledge representation tools—such as knowledge graphs and visual maps—tend to treat these approaches separately, which does not align with how humans naturally integrate both forms.\n\n" +
  
    "# Visualization\n\n"+
    "What’s been especially interesting to me is visualization.\n\n"+
    "Try visualizing an apple on a table. You might assume you have a clear image in mind, like a photo you would take on your camera. But what’s the color of the table? Where is this apple positioned? What material is the table made of?\n\n"+
    "Chances are, those details weren't included in your initial visualization until you were explicitly asked about them.\n\n"+
    "Our visualizations are abstract, meaning we focus on the concepts and relationships between them rather than their sensory details. The apple is on the table—but where, exactly? It doesn't matter until it becomes relevant. We hold abstract, flexible representations that provide just enough information to understand the concept, rather than a photographic image. This is similar to how we use graphs and maps: we represent knowledge in ways that are abstract and adaptable to context, rather than rigid and exhaustive.\n\n"+

    "# Open Questions\n\n"+
    "Can we integrate graphs and maps into a cohesive representation that better reflects how we naturally think?\n\n"+
    "What are we really thinking of when we visualize? We often think of mental images as clear, complete pictures, but our actual visualizations balance abstract concepts and visuals. How should we think about this process?\n\n"    ,
    date: "2024-07-06"
  },
  {
    id: 3,
    title: "A More Human-Like Agent Through Reasoning & Action",
    slug: "react",
    excerpt: "A review over the ReAct framework",
    content: 
    "These are some paper notes I wrote for <a href='https://arxiv.org/pdf/2210.03629' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>ReAct: Synergizing Reasoning and Acting in Language Models</a>, which I sometimes do to better understand a paper so I decided to post one of these notes to share. \n\n" +
    
    "# Problem with Independent Reasoning and Acting \n\n" +
    "Most prior work has focused on either LLM reasoning or acting, which creates a gap between the LLM’s verbal reasoning and its ability to execute tasks. Reasoning traces are generated, but these traces do not always feed directly into action planning and execution.\n\n" +
    "In humans, reasoning and actions are tightly linked, so we use reasoning to inform our next steps in real time. Traditional LLMs, however, require explicit prompts to translate their reasoning into actions, and there's often no feedback loop that allows the model to adjust its reasoning based on the results of its actions. As a result, systems may be able to reason well but struggle to execute tasks that require real-time adjustments or complex decision-making that involves interacting with the environment.\n\n\n" +
    
    "# Dynamic Adaptation \n\n" +

    "The ReAct framework seeks to address this issue by allowing a system to act on its reasoning and adjust its approach based on feedback—similar to how humans dynamically adapt their actions as they think through a problem.\n\n" +
    "This framework includes a few key features, and we can demonstrate this through a cooking analogy:\n\n" +
    "- Progress tracking (“now that everything is cut, I should heat up the pot of water”)\n" +
    "- Exception handling (“I don’t have salt, so let me use soy sauce and pepper instead”)\n" +
    "- External information seeking (“how do I prepare dough? Let me search on the Internet”)\n\n" +
    "Personally, I think that exception handling is one of the most powerful capabilities of ReAct as it reflects how humans handle complex tasks in real-time and adjust plans accordingly. In the real world, these kinds of exceptions are common and some kind of evaluative process is necessary during the process. This is what allows humans to learn quickly and adapt, even under uncertainty.\n\n\n" +

    "# So How Does ReAct Work?\n\n" +

    "We’ll go through two cases that this research experiments with.\n\n"+
    "Case 1: Knowledge-intensive reasoning tasks (multi-hop question answering, fact verification)\n\n" +
    "We add language-based reasoning steps to expand the agent’s action space (choices the agent has at each time step when interacting with its surroundings). Previously, the action space only included external actions, such as commands to query a database in a question answering task.\n\n" +
    "In ReAct, the action space is augmented to include both traditional actions and internal reasoning steps. Traditional actions might be to simply search for a term on Wikipedia for a question answering task. Reasoning adds additional actions to this, like \"I should search for X first, then look for Y,\" or \"this information doesn’t match, so I need to adjust my search.\"\n\n" +
    "These reasoning steps update the context, which helps in generating more informed actions later on. For example, a thought might help the agent realize it needs to search for additional information, thereby influencing the next action it takes.\n\n" +
    "We can go through an example mentioned in the paper:\n\n"+
    "![react 1](/images/react1.png)\n\n" +
    "We aim to answer: \"Aside from the Apple Remote, what other device can control the program Apple Remote was originally designed to interact with?\"\n\n"+
    "Using ReAct, the agent alternates between reasoning and taking actions, allowing it to solve the task effectively:\n\n"+
    "- Thought 1: It reasons that it should search for the program the Apple Remote was originally designed for\n" +
    "- Act 1: It searches for \"Apple Remote\" and discovers that it was designed to control the \"Front Row media center\"\n" +
    "- Thought 2: Realizing this, the agent decides to search for \"Front Row\" to find out what other devices control it\n" +
    "- Act 2 & 3: It searches for \"Front Row\" and finds that it's a discontinued software\n" +
    "- Thought 4: It reasons that Front Row can be controlled by the Apple Remote and by keyboard function keys\n" +
    "- Act 4: It concludes that the correct answer is \"keyboard function keys\"\n" +
    
    "Case 2: Decision making tasks (which involve interacting with complex environments over long periods with sparse rewards)\n\n"+
    "The paper goes over two tasks:\n\n"+
    "1. ALFWorld\n\n"+
    "*Task Description*\n\n"+
    "An agent navigates a simulated household and interacts with objects to achieve a goal (\"examine paper under desklamp\").\n\n"+
    "*Why is this complex?*\n\n"+
    "An agent may need to take over 50 actions to complete a single task, and it requires reasoning about where items are likely to be located.\n\n"+
    "*Process*\n\n"+
    "ReAct is prompted using annotated trajectories from training data, which include both actions (\"go to coffeetable 1\") and reasoning that help break down complex goals (\"I need to find a desklamp, so I should check desks and shelves\"). The thoughts guide the agent in decomposing goals, tracking progress, and reasoning about where objects are likely located.\n\n"+
    "*Conclusion*\n\n"+
    "ReAct is compared to Act-only (agent follows similar actions but without reasoning) and BUTLER (imitation learning agent trained on 105 expert trajectories).\n\n"+
    "![react 2](/images/react2.png)\n\n" +
    "The key advantage of ReAct is that it uses reasoning to plan subgoals and track progress, which leads to better performance in navigating and interacting with the environment.\n\n\n"+
    
    "2. WebShop\n\n"+
    "*Task Description*\n\n"+
    "Online shopping environment where the agent must find and purchase products based on user instructions (\"I am looking for a nightstand with drawers. It should have a nickel finish, and priced lower than $140\").\n\n"+
    "*Why is this complex?*\n\n"+
    "There is a large variety of real-world product data (titles, descriptions, options) and requires the agent to interact with this data through text-based actions.\n\n"+
    "*Process*\n\n"+
    "Thoughts help the agent reason about the product attributes and options based on the user's request. For example, if the instruction is to find a \"space-saving ottoman bench,\" the thought process might help the agent reason that \"39x18x18 inches\" is an acceptable size and \"blue\" is a suitable color.\n\n"+
    "*Conclusion*\n\n"+
    "ReAct’s reasoning helps it bridge the gap between noisy product descriptions and the required attributes in the user instructions, leading to better product selection.\n\n\n"+
    
    "# Closing Thoughts\n\n" +
    "ReAct integrates reasoning and action in a unified method to address limitations in prior work that only focused on one or the other. This approach also produces interpretable decision traces, meaning the reasoning and actions taken are clear and understandable.\n\n" +
    "I recently applied this to DevSearch, which is an agent that performs web retrieval over documentation and GitHub repos to help developers with dev tasks that require up-to-date information. It involves coming up with an initial action based on the user’s codebase and query, then reasoning to determine which steps to take next. It goes within these pages, and determines which actions to take next (click on buttons or hyperlinks) based on what is on the page and what information it still needs. This integration of reasoning and action was necessary for a project like this, as context needed to be constantly updated as the agent went along its journey.\n\n"
    ,
    date: "2024-09-15"
  }
  // You can add more writings here later
];

export default writings;