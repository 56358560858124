import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useParams } from 'react-router-dom';
import writings from '../data/writings';
import '../styles/sharedStyles.css';

const FullArticle = () => {
  const { slug } = useParams();
  const article = writings.find(w => w.slug === slug);

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <article className="max-w-3xl mx-auto">
      <h1 className="text-4xl font-bold mb-4">{article.title}</h1>
      <p className="text-gray-600 mb-8">{article.date}</p>
      <ReactMarkdown 
        className="prose lg:prose-xl markdown custom-article-font"
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ({node, ...props}) => (
            <div className="flex justify-center">
              <img 
                {...props} 
                src={props.src.startsWith('http') ? props.src : `${process.env.PUBLIC_URL}${props.src}`}
                alt={props.alt || "Article image"}
                className="max-w-full h-auto my-8" 
                style={{maxWidth: '550px'}}
              />
            </div>
          ),
        }}
      >
        {article.content}
      </ReactMarkdown>
    </article>
  );
};

export default FullArticle;