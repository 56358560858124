const projects = [
    {
      id: 1,
      title: "q:uery",
      slug: "query",
      blurb: "A search agent that reasons like a human",
      image: "/images/querypage.png",
      content: 
      "# Motivation\n\n" +
      "Search is a tedious process—we know what we want to find, and that information is out there, but the journey from start to finish is inefficient. My goal was to create a search agent that mimics our natural search process, optimizing for source quality and credibility.\n\n" +

      "Initially, I optimized for latency, using techniques like doing query expansion to generate advanced search queries. However, there is a significant trade-off between latency and quality when it comes to search (at least its current state).\n\n" +

      "The reality is that search engines are designed for humans to iterate upon—each search leads to another as we continuously refine what we’re looking for.\n\n" +

      "Thus, search generally involves these two key components:\n" +
      "1. Evaluating sources for relevance and credibility\n" +
      "2. Conducting specific searches based on information seen to get incrementally closer to the goal\n\n" +

      "I aimed to automate these two components to replicate a human-driven search process.\n\n" +

      '<video class="custom-video" controls>\n' +
      '  <source src="/videos/demo.mp4" type="video/mp4">\n' +
      '  Your browser does not support the video tag.\n' +
      '</video>\n\n' +

      "You can try it out <a href='https://querysearch.live' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>here</a>\n\n" +

      "# Summary\n\n" +
      "This search agent comes in the form of a web app demo and Chrome extension. Both versions offer the same functionality, but the Chrome extension is designed to better integrate into a typical Google search experience.\n\n" +

      "Simply provide a query in natural language, and the agent will browse the web on your behalf.\n\n" +

      "Here’s the process:\n" +
      "1. **Classification**: given a query, a fine-tuned DistilBERT model classifies the specific search type this query belongs to (e.g. “research paper”, “technical guide”, etc.). This helps to set evaluation criteria and search methods specific to this type.\n" +
      "2. **Initial search**: an initial Google search is performed with the original query, sometimes appended with “site:” operators depending on the search type\n" +
      "3. **Evaluation**: A cross-encoder is used to evaluate content relevance, while an LLM assesses source credibility to identify the top 5 most relevant & credible results\n" +
      "4. **Deeper search**: content of the top 2 results is scraped, clicking on website elements like “see more” or “view pdf” as needed to extract all relevant information. This information is used to craft iterative queries, considering both the user’s original query and gathered content\n" +
      "5. **Repeat**: this process is repeated until the agent determines that the results meet the user’s request, or until a maximum of 4 iterations is reached to prevent infinite loops\n\n" +

      "# Future Thoughts\n\n" +
      "One common problem I faced was with the actual web navigation, which was difficult due to unstandardized HTML structures and rendering differences across various websites. I think an interesting idea to explore here is a web interface specifically designed for AI—some way to convert these websites into a standardized format that makes them easy for AI to interact with.\n\n" +

      "Another future direction is adding feature control, allowing users to specify edits like 'more sources that feel like this article' or 'more academic sources'. This builds upon the iterative nature of search, but in a much more intuitive way. Instead of crafting complex queries to achieve a specific feature, users could simply request the feature directly.\n"
    },
    {
      id: 2,
      title: "devsearch",
      slug: "devsearch",
      blurb: "VSCode extension with web retrieval",
      image: "/images/devsearch.png",
      content: 
      "# Motivation\n\n" +
      "AI is widely used for coding tasks, but it becomes less useful when it comes to developer tasks beyond that—like deploying to a server, using a new framework, etc. For this kind of guidance, we need up to date information and these kinds of documentation are constantly changing.\n\n" +

      "I wanted a ChatGPT-like interface within VSCode that could perform real-time web retrieval for code documentation and GitHub repos.\n\n" +

      "This won “most technically innovative” at <a href='https://x.com/ExaAILabs/status/1834655862486712504' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>Exa's retrieval hackathon</a>.\n\n" +

      '<video class="custom-video" controls>\n' +
      '  <source src="/videos/devsearch-demo.mp4" type="video/mp4">\n' +
      '  Your browser does not support the video tag.\n' +
      '</video>\n\n' +

      "<a href='https://github.com/kellyhongsn/devsearch' target='_blank' style='text-decoration: underline; text-decoration-color: #002fa7; text-underline-offset: 3px;'>Github</a>\n\n" +

      "# Summary\n\n" +

      "Here’s how it works:\n" +
      "1. **Query & file processing**: take in the user’s query and uploaded code files to create a plan that considers the project’s context (so we can ensure that the retrieved information is compatible and directly applicable)\n" +
      "2. **Web search**: use Exa’s search API for GitHub repos and Serper for Google searches\n" +
      "3. **Initial response**: considering information retrieved from web sources, project context, and user’s query, develop an initial response\n" +
      "4. **Web navigation**: navigate within web pages to reveal further information as needed (e.g. clicking on examples or corresponding sections of code documentation)\n" +
      "5. **Final response**: give the final response considering additional information retrieved\n"+

      "# Future Thoughts\n\n" +
      "Currently, the agent performs web retrieval each time it’s prompted, which can be time-consuming and inefficient. A potential idea to explore could be maintaining an open-source database of up-to-date documentation that is preprocessed and indexed for efficient access. This would reduce the need for real-time retrieval while still ensuring that developers have access to the most current information.\n\n"

    }
    // Add more projects as needed
  ];
  
  export default projects;