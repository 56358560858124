import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import writings from '../data/writings';
import '../styles/sharedStyles.css'; // Import the shared styles

const WritingsPage = () => {
    const navigate = useNavigate();

    const handleWritingClick = (slug) => {
        navigate(`/writings/${slug}`);
    };

    return (
        <div className="min-h-[50vh]">
            <h1 className="text-3xl font-bold mb-8">Writings</h1>
            <div className="space-y-8">
                {writings.map((writing) => (
                    <article 
                        key={writing.id} 
                        className="writing-block"
                        onClick={() => handleWritingClick(writing.slug)}
                    >
                        <h2 className="writing-title">{writing.title}</h2>
                        <ReactMarkdown className="writing-excerpt markdown">{writing.excerpt}</ReactMarkdown>
                        <p className="writing-date">{writing.date}</p>
                    </article>
                ))}
            </div>
        </div>
    );
};

export default WritingsPage;